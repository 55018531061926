import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import Customers from "components/ui/extended/Customers";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import QuestionsAndAnswers from "components/ui/base/QuestionsAndAnswers";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { REVIEWS_STRUCTURED_DATA } from "constants/seo";
import { CannedResponseTemplate, ChatTemplate } from "app-types/template";
import PlainHeaderWithVideo from "components/ui/base/PlainHeaderWithVideo";
import VideoPreview from "components/ui/base/VideoPreview";

interface LiveChatPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    metaImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: LiveChatPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.conversational-ai.seo.title")}
        description={t("pages.conversational-ai.seo.description")}
        image={{
          relativePath: "meta/customerly-live-chat-with-superpower.jpg",
          alt: "Live Chat with Superpowers",
        }}
        structuredData={[
          {
            "@context": "https://schema.org",
            "@type": "Product",
            name: t("pages.conversational-ai.seo.title"),
            image: [
              data.site.siteMetadata.siteUrl +
                data.metaImage.childImageSharp.gatsbyImageData.images.fallback!
                  .src,
            ],
            description: t("pages.conversational-ai.seo.description"),
            brand: {
              "@type": "Brand",
              name: "Customerly",
            },
            review: REVIEWS_STRUCTURED_DATA,
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: 4.9,
              reviewCount: 389,
            },
          },
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [1, 2, 3].map((index: number) => {
              return {
                "@type": "Question",
                name: t(`pages.conversational-ai.faq.${index}.question`),
                acceptedAnswer: {
                  "@type": "Answer",
                  text: t(`pages.conversational-ai.faq.${index}.answer`),
                },
              };
            }),
          },
        ]}
      />

      <PlainHeaderWithVideo
        title={t("pages.conversational-ai.title")}
        description={t("pages.conversational-ai.description")}
        titleMaxWidth={960}
        descriptionMaxWidth={700}
        ctaRegister={{ show: true }}
        wistia={{
          id: "pyphgtzujb",
        }}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Features",
            path: "/features/",
          },
          {
            name: "Conversational AI",
            path: "/conversational-ai/",
          },
        ]}
      />

      <Customers />

      <VideoPreview
        title={t("pages.conversational-ai.1.title")}
        paragraph={t("pages.conversational-ai.1.subtitle")}
        storiesPosition={"left"}
        stories={[
          {
            relativePath: "pages/conversational-ai/in-chat-autoreply.mp4",
          },
        ]}
      />

      <VideoPreview
        title={t("pages.conversational-ai.2.title")}
        paragraph={t("pages.conversational-ai.2.subtitle")}
        storiesPosition={"right"}
        stories={[
          {
            relativePath: "pages/conversational-ai/ai-reply.mp4",
          },
        ]}
      />

      <VideoPreview
        title={t("pages.conversational-ai.3.title")}
        paragraph={t("pages.conversational-ai.3.subtitle")}
        storiesPosition={"left"}
        stories={[
          {
            relativePath: "pages/conversational-ai/ai-write-for-me.mp4",
          },
        ]}
      />

      <VideoPreview
        title={t("pages.conversational-ai.4.title")}
        paragraph={t("pages.conversational-ai.4.subtitle")}
        storiesPosition={"right"}
        stories={[
          {
            relativePath:
              "pages/conversational-ai/ai-summarize-conversation.mp4",
          },
        ]}
      />

      <VideoPreview
        title={t("pages.conversational-ai.5.title")}
        paragraph={t("pages.conversational-ai.5.subtitle")}
        storiesPosition={"left"}
        stories={[
          {
            relativePath: "pages/conversational-ai/ai-create-articles.mp4",
          },
        ]}
      />

      <RegisterForCustomerService />

      <QuestionsAndAnswers
        title={t("pages.conversational-ai.faq.title")}
        description={t("pages.conversational-ai.faq.description")}
        elements={[1, 2, 3, 4, 5].map((index) => {
          return {
            question: t(`pages.conversational-ai.faq.${index}.question`),
            answer: t(`pages.conversational-ai.faq.${index}.answer`),
          };
        })}
      />
    </GlobalWrapper>
  );
};

export const liveChatQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaImage: file(
      relativePath: { eq: "meta/customerly-live-chat-with-superpower.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
